import {Injectable} from '@angular/core';
import {ReplaySubject} from 'rxjs';
import {ReferenceModel} from '../models/referencemodel';

@Injectable({
  providedIn: 'root'
})
export class CompanyStateService {

  readonly activeCompany = new ReplaySubject<ReferenceModel>(1);
  readonly availableCompanies = new ReplaySubject<ReferenceModel[]>(1);

}
